<template>
	<div class="viewAPI2">
		<van-nav-bar
		    :title="$t('system.APIset')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="content bgWhite">
						<!-- <span class="help-btn" @click="getId()">帮助</span> -->
						<div class="body1Style">
							<!-- <p class="body1StylepFont">
								<span class="mainFontLightColor">{{ $t('system.accountnumber') }}: </span>
								<span class="mainFontLightColor" v-if="vcode_method == 'phone'">+86 {{ phone }}</span>
								<span class="mainFontColor" v-if="vcode_method == 'email'">{{ email }}</span>
							</p> -->
							
							<!-- <div class="bodyStyleDivStyle">
									<div class="bd" slot="contslot">
										<input type="number" class="inp" v-model="inputCode" :placeholder="$t('login.PleaseEnterVerificationCode')" />
										<div class="button" @click="onSendCode">
											<span v-if="codeSending" class="mainColor1">({{ theTime }})</span>
											<span v-else class="mainColor1">{{ $t('sPhone.getCode') }}</span>
										</div>
									</div>
							</div> -->
			
							<div class="bodyStyleDivStyle1">
								<p class="mainFontLightColor">API KEY</p>
								<van-row>
									<van-col span="24"><input class="body1StyleInputStyle" v-model="apiUpdate.key" type="text" :placeholder="$t('system.enterAPIKey')" /></van-col>
								</van-row>
							</div>
			
							<div class="bodyStyleDivStyle2">
								<p class="mainFontLightColor">Secret KEY</p>
								<van-row>
									<van-col span="24"><input class="body1StyleInputStyle" v-model="apiUpdate.secret" type="text" :placeholder="$t('system.enterSecretKey')" /></van-col>
								</van-row>
							</div>
			
							<div class="bodyStyleDivStyle2" v-if="exchange_id == 3">
								<p class="mainFontLightColor">Passphrase</p>
								<van-row>
									<van-col span="24"><input class="body1StyleInputStyle" v-model="apiUpdate.passphrase" type="text" :placeholder="$t('system.enterPassphrase')" /></van-col>
								</van-row>
							</div>
						</div>
						<div class="bottom2DivStyle">
							<p class="bottom2PStyle">
								1.{{ $t('system.setWord1',{exchange:
									exchange_name=='huobi'?$t('system.huobi'):
									exchange_name=='bian'?$t('system.bian'):
									exchange_name=='okex'?$t('system.OKEx'):
									exchange_name=='bitrocket'?$t('system.bitrocket'):
									exchange_name.toUpperCase()
								}) }}
							</p>
							<p class="bottom2PStyle">
								2.{{ $t('system.setWord2') }}
							</p>
							<p class="bottom2PStyle">
								<span>3.{{ $t('system.setWord3') }}</span>
							</p>
						</div>
			
						<div class="bottom1">
							<div class="queren" @click="setKey()">{{ $t('tankuang.yes') }}</div>
							<div class="queren" @click="jumpOut" v-if="exchange_id==1||exchange_id==3||exchange_id==4">{{ $t('system.openExchange') }}</div>
			<!-- 				<van-row type="flex" justify="space-around">
								<van-col span="11"><van-button class="btnStyle" style="margin: auto;display: block" @click="setKey()" type="primary">{{ $t('system.setup') }}</van-button></van-col>
								<van-col span="11"><van-button class="btnStyle" style="margin: auto;display: block" @click="getKey()" type="primary">{{ $t('redRecode.examine') }}</van-button></van-col>
							</van-row> -->
						</div>
					</div>
		</div>
		
	</div>
</template>

<script>

export default {
	name: 'viewAPI2',
	components: {
		// TitleTwo,
	},
	data() {
		return {
			value: '00000',
			phone: '', //  用户手机号
			// popupShow: false,
			// popupShow2: false,
			// popupShow3: false,
			showKeyboard: false,
			exchange_name: '',
			email: '',
			codeSending: false,
			inputCode: '',
			exchange_id: 0,
			article_id: 0,
			list2: {},
			flag: false,
			ifhave: false,
			url: '',
			theTime: 60,
			vcode_method: 'phone',
			show: false,
			apiUpdate: {
				key: '',
				secret: '',
				passphrase: ''
			}
		};
	},
	created() {
		this.exchange_name = this.$route.query.exchange_name;
		this.exchange_id = this.$route.query.exchange_id;
		// this.getKey();
		this.initData();
		// this.getKey();
	},

	methods: {
		jumpOut(){
			if(this.exchange_id==1){
				// 跳火必推广链接
				window.open('https://www.huobi.com/zh-cn/v/register/double-invite/?inviter_id=11345710&invite_code=k53x6223')//, '_self'
			}else if(this.exchange_id==3){
				// 跳okx推广链接
				window.open('https://www.okx.com/join/FOLLOWWIN')//, '_self'
			}else{
				// 跳bitrocket推广链接4
				window.open('https://m.bitrocket.vip/zh_CN/register?inviteCode=WQQVLGQL')//, '_self'
			}
		},
		getId() {
			this.$post2('Article/Api/Index/getExchangeHelpID', { exchange_id: this.exchange_id }).then(res => {
				this.$router.push(`viewArticle?id=${res.id}`);
			});
		},

		initData() {
			this.$post2('user/api/index/getMetaInfo', {})
				.then(res => {
					this.email = res.email;
					this.phone = res.phone;
					this.vcode_method = res.vcode_method;

					//邀请链接-弃用
					if (this.exchange_id == 1) {
						this.url = res.huobi_link;
					} else if (this.exchange_id == 2) {
						this.url = res.bian_link;
					} else if (this.exchange_id == 3) {
						this.url = res.okex_link;
					}
					if (this.vcode_method == 'phone') {
					}
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		// 获取验证码
		onSendCode() {
			if (this.codeSending) {
				return;
			}
			let url = 'security/user/authsmscode';
			if (this.vcode_method == 'email') {
				url = 'security/user/authemailcode';
			}
			this.theTime = 60;
			this.codeSending = true;
			this.doCountDown();
			this.$post2(url, {
				op: 'opsecret'
			}).then(res => {
				console.log(res);
				this.sid = res.sid;
			});
		},
		// 又改成 一次定时器了 写的真烂...
		doCountDown() {
			if (this.theTime > 1) {
				this.theTime--;
				this.codeTimer = setTimeout(() => {
					this.doCountDown();
				}, 1000);
			} else {
				this.codeSending = false;
			}
		},
		
		// 获取交易所Key
		// getKey() {
		// 	this.$post2('Trade/Api/Index/getApiKey', {
		// 		exchange_id: this.exchange_id,
		// 		sid: this.sid,
		// 		code: this.inputCode
		// 	})
		// 		.then(res => {
		// 			this.apiUpdate.key = res.api_key;
		// 			this.apiUpdate.secret = res.api_secret;
		// 			this.article_id = res.article_id;
		// 		})
		// 		.catch(e => {
		// 			this.$toast.fail(e);
		// 		});
		// },

		// 设置交易所Key
		setKey() {
			this.$post2('Trade/Api/Index/setApiKey', {
				exchange_id: this.exchange_id,
				key: this.apiUpdate.key,
				secret: this.apiUpdate.secret,
				passphrase: this.apiUpdate.passphrase,
				sid: this.sid,
				code: this.inputCode
			})
				.then(res => {
					this.$toast(this.$t('system.setSuc'));
					// 修改默认交易所
					var userInfo = JSON.parse(localStorage.userInfo);
					userInfo['default_exchange'] = this.exchange_name;
					localStorage.userInfo = JSON.stringify(userInfo);
					this.onClickLeft();
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		onClickRight() {
			this.$router.push({
				path: 'viewArticle',
				query: {
					id: this.article_id
				}
			});
		},
		onClickLeft() {
			this.$router.back(-1);
		},
		onCancel() {
			Toast('取消');
		},
		btnChange() {
			this.show = true;
		}
	}
};
</script>

<style lang="less" scoped>
.viewAPI2 {
	// background: #ffffff;
	font-family: PingFangSC-Regular;

	.bodyStyleDivStyleP {
		margin: 0.56rem 0 0.2rem 0;
	}

	.body1StyleInputStyle {
		border: 0;
		font-size: 0.28rem;
		width: 80%;
		line-height: 0.7rem;
	}
	.body1StyleInputStyle::-webkit-input-placeholder,
	.body1StyleInputStyle:-moz-placeholder,
	.body1StyleInputStyle::-moz-placeholder,
	.body1StyleInputStyle:-ms-input-placeholder,
	.body1StyleInputStyle::-ms-input-placeholder,
	.body1StyleInputStyle::placeholder {
		// color: #D6D6D6 !important;
		color: #FFFFFF !important;
	}

	.bottom2DivStyle {
		margin-top: 0.6rem;
		// border-top: 0.27rem solid RGBA(246, 248, 249, 1);
		// padding-top: 0.29rem;
		margin-bottom: 1rem;
	}

	/*.bodyStyleDivStyle {*/
	/*    border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
	/*    padding-bottom: 0.1rem;*/
	/*}*/

	.pc_in {
		position: relative;
		float: left;
		width: 6.7rem;
		height: 1.12rem;
		border: none;
	}

	.pc_in .divbox {
		display: flex;
		width: 100%;
		height: 1.12rem;
		overflow: hidden;
	}

	.pc_in .divbox span {
		flex: 1;
		height: 100%;
		border-bottom: #e6e8ed solid 1px;
		border-right: none;
		margin: 0 0.16rem;
	}

	.pc_in .divbox span.bl {
		border-color: #000;
	}

	.pc_in p {
		position: absolute;
		width: 100%;
		height: 1.12rem;
		line-height: 1.12rem;
		top: 0;
		left: 0;
		background: none;
		overflow: hidden;
	}

	.pc_in p input {
		float: left;
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
		margin-top: 0.2rem;
		letter-spacing: 0.84rem;
		background: none;
		text-indent: 0.4rem;
		font-size: 0.48rem;
	}

	.getCodeBtn {
		color: #EFBA0D;
		font-size: 0.24rem;
		float: right;
		border: none;
		background: none;
	}

	.bottom2PStyle {
		font-size: 0.24rem;
		font-weight: 500;
		color: #666666;
		margin: 0 0.4rem;
		margin-bottom: 0.2rem;
	}

	.bottom3PStyle {
		color: #0f0f0f;
		margin: 0.3rem;
		font-size: 0.3rem;
	}

	.body1StyleSPan {
		color: #ff4b04;
	}

	.body1StylepFont {
		color: #666666;
		// margin-top: 0.2rem;
		font-size: 0.28rem;
		margin-bottom: 0.51rem;
		.mainFontLightColor {
			color: #333333 !important;
		}
	}

	/* @import "../../assets/css/puliceStyle.css"; */

	.body1StyleInputStyle {
		font-size: 0.28rem;
		color: #333333;
		border-bottom: 0.01rem solid #D3D9DE;
		width: 100%;
	}
	input::-webkit-input-placeholder {
	    /* 修改字体颜色 */
	    color: #999999;
	    /* 修改字号，默认继承input */
	    font-size: 12px;
	}
	.bodyStyleDivStyleP {
		margin-top: 0.3rem;
		margin-bottom: 0.2rem;
	}

	.bodyStyleDivStyle {
		line-height: 0.4rem;
		// border-bottom: 1px solid #f2f2f2;
		margin-bottom: 0.2rem;
		.bd {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 0.2rem;
			border-bottom: 1px solid #EDEDED;
			.inp {
				border: none;
				color: #333333;
				font-size: 0.28rem;
			}
			.button {
				font-size: 0.24rem;
				color: #EFBA0D;
			}
		}
		// padding-bottom: 0.1rem;
	}
	.bodyStyleDivStyle1 {
		margin-top: 0.6rem;
		.mainFontLightColor {
			font-size: 0.28rem;
			color: #333333 !important;
			font-weight: bold;
		}
	}
	.bodyStyleDivStyle2 {
		margin-top: 0.2rem;
		.mainFontLightColor {
			font-size: 0.28rem;
			color: #333333 !important;
			font-weight: bold;
		}
	}

	.body1Style {
		font-size: 0.28rem;
		padding: 0.3rem 0.4rem;
	}

	.bottom1 {
		padding-bottom: 0.6rem;
		.queren {
			width: 6.7rem;
			height: 0.9rem;
			margin: 0 auto 0.2rem;
			background-color: #FAD538;
			font-size: .32rem;
			color: #333333;
			line-height: 0.9rem;
			text-align: center;
			border-radius: 0.16rem;
		}
	}
	.help-btn {
		color: #29aae1;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 999;
	}
}
.content {
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	margin-top: 0.2rem;
	height: calc(100vh - 1.1rem);
}
.btnStyle {
	width: 3rem;
	height: 0.9rem;
	background-color: #FAD538;
	border-radius: 0.16rem;
	line-height: 0.9rem;
	font-size: 0.32rem;
	color: #333333;
}
</style>
